/**
 * https://jira.beesat.com/browse/MDEV-1484
 */
(function(angular){
    "use strict";

    angular.module("app")
        .run(function($rootScope, _view) {
            var roles = {
                "ROLE_ACCOUNT_OWNER": 100,
                "ROLE_ACCOUNT_EDIT": 50,
                "ROLE_ACCOUNT_VIEW": 10
            };

            // Required role for an action
            var actions = {
                editMap: roles["ROLE_ACCOUNT_EDIT"],

                editUser: roles["ROLE_ACCOUNT_OWNER"],
                inviteUsers: roles["ROLE_ACCOUNT_OWNER"],
                cancelInvite: roles["ROLE_ACCOUNT_OWNER"],
                repeatInvite: roles["ROLE_ACCOUNT_OWNER"],

                editFirstDayOfWeek: roles["ROLE_ACCOUNT_OWNER"],
                manageAccount: roles['ROLE_ACCOUNT_OWNER'],

                manageCrops: roles['ROLE_ACCOUNT_EDIT'],

                addTask: roles["ROLE_ACCOUNT_EDIT"],
                addSpray: roles["ROLE_ACCOUNT_EDIT"]
            };

            /**
             * Usage:
             * can('editMap');
             * can('addTask');
             *
             * @param {string} action
             * @returns {boolean}
             */
            $rootScope.can = function(action) {
                return _view.getCurrentAccountInfo() && roles[_view.getCurrentAccountInfo().role] >= actions[action];
            };
        })
}(angular));